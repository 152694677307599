import { PaymentStatistic } from '@/features/statistics/payments'
import { useGetUsers } from '@/features/users/api'
import { useQueryParams, useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { FC } from 'react'

const Payments: FC = () => {
	const { getMessage } = useTextLocality()
	const { id } = useQueryParams()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={
				id
					? getMessage('payment_statistic_user')
					: getMessage('payment_statistic')
			}
		>
			<PaymentStatistic />
		</Layout>
	)
}

export default Payments
