import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate } from 'react-intl'

import { useTextLocality, useTranslationField } from '@/shared/hooks'
import { Button, Checkbox } from '@/shared/ui/form'
import clsx from 'clsx'
import { DeleteIconJSX, EditIconJSX } from '@/shared/assets/common/svg'
import '@/Assets/sass/Pages/School/Card.sass'

import styles from './SchoolCard.module.scss'
import { IAnalytic } from '@/shared/services/types/analytics.types'

interface IProps {
	setConfirmationDeletion?: any
	setCategoryID?: any
	updated_at?: string
	detailList: boolean
	editLink: string
	activeLink?: any
	status_edit: boolean
	isLine?: boolean
	checkedCard: boolean
	onChecked?: (id: number) => void
	data: IAnalytic.ExpertData
}

export const ExpertsCard: FC<IProps> = ({
	status_edit,
	updated_at,
	editLink,
	activeLink,
	setConfirmationDeletion,
	isLine,
	setCategoryID,
	checkedCard,
	onChecked,
	data
}) => {
	const { getField } = useTranslationField()
	const title = getField(data, 'name')
	const category = getField(data, 'category')
	const description = getField(data, 'description')
	const { id } = data

	const { getMessage } = useTextLocality()
	const [checked, setChecked] = useState(data.is_active)

	const toggleStatusProduct = () => {
		setChecked(!checked)
		activeLink(id, !checked)
	}

	return (
		<div className={clsx(styles.wrapper, isLine && styles.isLineWrapper)}>
			{onChecked && (
				<div className={styles.select}>
					<Checkbox
						name='selectAll'
						modifier={['red']}
						checked={checkedCard}
						onChange={() => onChecked(id)}
					/>
				</div>
			)}

			<div className={styles.content}>
				<div className={styles.image}>
					<img src={data.img_link} alt='img product' />
				</div>
				<div className={styles.groupButton}>
					<Button modifiers={['inverse']} svg={<EditIconJSX />} link={editLink}>
						{getMessage('edit')}
					</Button>

					{!!setConfirmationDeletion && (
						<Button
							modifiers={['inverse']}
							svg={<DeleteIconJSX />}
							onClick={() => {
								setCategoryID(id)
								setConfirmationDeletion(true)
							}}
						>
							{getMessage('delete')}
						</Button>
					)}
				</div>
			</div>
			<div className={styles.info}>
				<div
					className={clsx(
						styles.headerArticle,
						!!updated_at && styles.withDate
					)}
				>
					{!!updated_at && (
						<div className={styles.date}>
							<FormattedDate value={new Date(updated_at)} />
						</div>
					)}
					<div className={styles.category}>
						{data.rating_name} ({data.raiting_int} XP)
					</div>
					{!!status_edit && (
						<div className={styles.status}>
							<Checkbox
								name='status'
								checked={checked}
								title={checked ? getMessage('show') : getMessage('hide')}
								modifier={['card']}
								onChange={toggleStatusProduct}
							/>
						</div>
					)}
				</div>
				<div className={styles.title}>{title}</div>
				<div className={styles.category}>{category}</div>
				<div
					className={styles.description}
					dangerouslySetInnerHTML={{ __html: description }}
				/>

				<div className={styles.moreDetailed}>
					<Link
						to={`/content/experts/${id}/analytics`}
						className='moreDetailed'
					>
						{getMessage('show_analytic')}
					</Link>
				</div>
			</div>
		</div>
	)
}
