import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/*
 * Возвращает объект query параметров из url
 * */

type QueryParamsType = {
	[key: string]: string
}
export const useQueryParams = () => {
	const { search } = useLocation()

	const [queryParams, setQueryParams] = useState<QueryParamsType>({})

	useEffect(() => {
		const urlParams = new URLSearchParams(search)
		const params = Object.fromEntries(urlParams.entries())

		setQueryParams(params)
	}, [search])

	return queryParams
}
