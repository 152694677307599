export const formatTextForEditor = (value?: string) => {
	if (!value) return ''
	return value
		.replaceAll('<p>', '')
		.replaceAll('</p>', '')
		.split('\n')
		.map(line => line.trim())
		.filter(line => line.length > 0)
		.map(line => `<p>${line}</p>`)
		.join('')
}
