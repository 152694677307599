import { Button, FormWrapper, Input, RadioGroup } from '@/shared/ui/form'
import styles from './UsersFilter.module.scss'
import {
	FieldValues,
	SubmitHandler,
	useForm,
	UseFormReturn
} from 'react-hook-form'
import {
	FilterIconJSX,
	SearchInput,
	SortIconJSX
} from '@/shared/assets/common/svg'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'
import clsx from 'clsx'
import { FC, useRef } from 'react'
import { useTextLocality } from '@/shared/hooks'
import { CalendarInput } from '@/shared/ui/form/calendar-input'

interface IProps {
	form: UseFormReturn<any>
	onSubmit: SubmitHandler<FieldValues>
}
export const UsersFilter: FC<IProps> = ({ onSubmit, form }) => {
	const { getMessage } = useTextLocality()

	const sortRef = useRef(null)
	const filterRef = useRef(null)
	const { watch, getValues } = form

	const options = [
		{
			label: getMessage('sortAZ'),
			value: 'Sort from A to Z'
		},
		{
			label: getMessage('sortZA'),
			value: 'Sort from Z to A'
		}
	]

	const options2 = [
		{
			label: getMessage('active'),
			value: 'Active'
		},
		{
			label: getMessage('inactive'),
			value: 'Inactive'
		}
	]

	const resetSort = () => {
		onSubmit({
			fromDate: undefined,
			toDate: undefined,
			transaction_counter: undefined
		})
		form.reset({
			...getValues(),
			sort: undefined,
			fromDate: undefined,
			toDate: undefined
		})
	}
	const resetFilter = () => {
		onSubmit({
			fromDate: undefined,
			toDate: undefined,
			transaction_counter: undefined
		})
		form.reset({
			...getValues(),
			filter: undefined,
			fromDate: undefined,
			toDate: undefined,
			transaction_counter: undefined
		})
	}

	return (
		<FormWrapper
			methods={form}
			className={styles.wrapper}
			onSubmit={data => {
				const res = {
					...data,
					transaction_counter:
						+data.transaction_counter === 0
							? undefined
							: data.transaction_counter
				}
				onSubmit(res)
			}}
		>
			<Input
				containerClassName={styles.input}
				name='search'
				modifiers={['small']}
				connect
				placeholder={getMessage('searchByNameContact')}
				icon={<SearchInput />}
			/>
			<div className={styles.buttons}>
				<DropDownMenu
					refs={[filterRef]}
					header={
						<button
							type='button'
							className={clsx(
								styles.filterButton,
								watch('filter') && styles.activeButton
							)}
						>
							<FilterIconJSX />
						</button>
					}
					modifiers={['arrow']}
				>
					<div className={styles.wrapperFilter}>
						<div className={styles.title}>{getMessage('filters')}</div>
						<div className={styles.blockFilter}>
							<div className={styles.titleFilter}>{getMessage('activity')}</div>
							<RadioGroup connect name='filter' options={options2} />
						</div>

						<div className={styles.blockFilter}>
							<div className={styles.titleFilter}>{getMessage('by_date')}</div>
							<div className={styles.calendar}>
								<CalendarInput
									connect
									name='fromDate'
									containerClassName={styles.calendarInput}
									selectsStart
									maxDate={watch('toDate') || new Date()}
									startDate={watch('fromDate')}
									selected={watch('fromDate')}
									endDate={watch('toDate')}
									placeholder={getMessage('from_date')}
								/>
								<span>-</span>
								<CalendarInput
									connect
									name='toDate'
									containerClassName={styles.calendarInput}
									selectsEnd
									startDate={watch('fromDate')}
									endDate={watch('toDate')}
									selected={watch('toDate')}
									minDate={watch('fromDate')}
									maxDate={new Date()}
									placeholder={getMessage('to_date')}
								/>
							</div>
						</div>
						<div className={styles.blockFilter}>
							<div className={styles.titleFilter}>{getMessage('payments')}</div>
							<Input
								connect
								placeholder='0'
								name='transaction_counter'
								modifiers={['small']}
								type='number'
							/>
						</div>
						<div className={styles.buttons} ref={filterRef}>
							<Button
								modifiers={['inverse']}
								type='reset'
								onClick={resetFilter}
							>
								{getMessage('clear')} {getMessage('filters')}
							</Button>
							<Button type='submit' modifiers={['inverse', 'red']}>
								{getMessage('save')}
							</Button>
						</div>
					</div>
				</DropDownMenu>
				<DropDownMenu
					refs={[sortRef]}
					header={
						<button
							type='button'
							className={clsx(
								styles.filterButton,
								watch('sort') && styles.activeButton
							)}
						>
							<SortIconJSX />
						</button>
					}
					modifiers={['arrow']}
				>
					<div className={styles.wrapperFilter}>
						<div className={styles.title}>{getMessage('sort')}</div>
						<div className={styles.blockFilter}>
							<div className={styles.titleFilter}>{getMessage('name')}</div>
							<RadioGroup connect name='sort' options={options} />
						</div>
						<div className={styles.buttons} ref={sortRef}>
							<Button modifiers={['inverse']} type='reset' onClick={resetSort}>
								{getMessage('clear')}
							</Button>
							<Button modifiers={['inverse', 'red']}>
								{getMessage('save')}
							</Button>
						</div>
					</div>
				</DropDownMenu>
			</div>
		</FormWrapper>
	)
}
