type InputObject = { [key: string]: any }
export const filterObject = (inputObj: InputObject): InputObject => {
	const result: InputObject = {}
	for (const key in inputObj) {
		if (inputObj[key] !== null && inputObj[key] !== undefined) {
			result[key] = inputObj[key]
		}
	}
	return result
}
