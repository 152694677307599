import axiosInstance from '@/shared/store/axiosGlobal'

import { getStatisticUrl } from '../api/config'
import {
	IUsersStats,
	ISummBets,
	ITransactionList,
	IResponseSummBets,
	ITransactionResponse,
	IUserBets,
	IUserBetsResponse
} from './types/statistic.types'
import { nextYearDate } from '../utils'

const StatisticService = {
	async getUsersBets(data: IUserBets) {
		const params = { toDate: nextYearDate(), ...data }

		return axiosInstance.get<IUserBetsResponse>(getStatisticUrl(`/bets`), {
			params: params
		})
	},

	async getUsersInfo(days: number) {
		return axiosInstance.get<IUsersStats>(getStatisticUrl(`/users_stats`), {
			params: {
				days
			}
		})
	},

	async getSumBetsUsers(data: ISummBets) {
		const params = { toDate: nextYearDate(), ...data }

		return axiosInstance.get<IResponseSummBets>(getStatisticUrl(`/sum_bets`), {
			params: params
		})
	},

	async getTransactionList(data: ITransactionList) {
		const params = { toDate: nextYearDate(), ...data }

		return axiosInstance.get<ITransactionResponse>(
			getStatisticUrl(`/transaction_list`),
			{
				params: params
			}
		)
	},

	async getTransactionDetail(transaction_id: number) {
		return axiosInstance.get<IUsersStats>(
			getStatisticUrl(`/transaction_detail_view`),
			{
				params: {
					transaction_id
				}
			}
		)
	}
}

export default StatisticService
