import { PaymentStatistics } from '@/entities/statistics/ui'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styles from './PaymentStatistic.module.scss'
import { TColumnsTable, Table } from '@/shared/ui/table'
import { Pagination } from '@/shared/ui/pagination'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'
import clsx from 'clsx'
import { FilterIconJSX } from '@/shared/assets/common/svg'
import { Button, FormWrapper, RadioGroup } from '@/shared/ui/form'
import { FieldValues, useForm } from 'react-hook-form'
import { useQueryParams, useTextLocality } from '@/shared/hooks'
import { usePaymentList } from '../api'
import { reFormatNum, transformDateLocale } from '@/shared/utils'

interface IData {
	id: number
	date: string
	deposit: number
	description: string
}

export const PaymentStatistic = () => {
	const { getMessage } = useTextLocality()
	const { id } = useQueryParams()

	const refFilter = useRef(null)
	const form = useForm()
	const defaultFilter = {
		page: 1,
		count: 7,
		totalCount: 1,
		user_id: id
	}

	const [filter, setFilter] = useState<any>({
		...defaultFilter
	})

	useLayoutEffect(() => {
		if (id) {
			setFilter((prev: any) => ({ ...prev, user_id: id }))
		}
	}, [id])

	const [dataTable, setDataTable] = useState<IData[]>([])
	const { data: dataPayment, isFetching } = usePaymentList(filter)

	const onSubmitFilter = (data: FieldValues) => {
		console.log({ data })
	}

	const onResetFilter = () => {
		form.reset({})
	}

	const columns = [
		{
			title: getMessage('id'),
			dataIndex: 'id'
		},
		{
			title: getMessage('date'),
			dataIndex: 'date',
			render: (date: string) => {
				return transformDateLocale(date, true)
			}
		},
		{
			title: getMessage('deposits_purchases'),
			dataIndex: 'deposit',
			render: (date: number) => {
				return reFormatNum(date, '₿')
			}
		},
		{
			title: getMessage('description'),
			dataIndex: 'description'
		},
		{
			title: '',
			dataIndex: 'filter',
			renderHeader: () => {
				const options = [
					{ value: 'deposits', label: getMessage('deposits') },
					{ value: 'purchase', label: getMessage('purchase') }
				]
				return (
					<div>
						<FormWrapper
							methods={form}
							className={styles.wrapper}
							onSubmit={onSubmitFilter}
						>
							<div className={styles.buttons}>
								<DropDownMenu
									refs={[refFilter]}
									header={
										<button
											className={clsx(
												styles.filterButton,
												form.watch('sort') && styles.activeButton
											)}
										>
											<FilterIconJSX />
										</button>
									}
									modifiers={['shadow', 'arrow', 'right']}
								>
									<div className={styles.wrapperFilter}>
										<div className={styles.title}>{getMessage('filters')}</div>
										<div className={styles.blockFilter}>
											<RadioGroup connect name='sort' options={options} />
										</div>
										<div className={styles.buttons} ref={refFilter}>
											<Button
												modifiers={['inverse']}
												type='reset'
												onClick={onResetFilter}
											>
												{getMessage('clear_filter')}
											</Button>
											<Button modifiers={['inverse', 'red']}>
												{getMessage('filter')}
											</Button>
										</div>
									</div>
								</DropDownMenu>
							</div>
						</FormWrapper>
					</div>
				)
			}
		}
	] as TColumnsTable[]

	useEffect(() => {
		const result =
			dataPayment?.transactions?.map(item => ({
				id: item.id,
				date: item.timestamp,
				deposit: item.amount,
				description: item.model_by
			})) || []
		setDataTable(result)
	}, [dataPayment])

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.backgroundWrap}>
				{!id && <PaymentStatistics />}

				<div className={styles.table}>
					<Table
						modifiers={['header', 'border']}
						isLoading={isFetching}
						columns={columns}
						styleWrapper={{ minHeight: '360px', height: 455 }}
						dataSource={dataTable}
					/>
				</div>
			</div>
			<Pagination
				pageCount={(dataPayment?.total_transactions || 1) / filter.count}
				onPageChange={onChangePage}
			/>
		</div>
	)
}
